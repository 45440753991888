import React, { useState, useEffect, useRef } from 'react';
import './Contact.less';
import { useTranslation } from "react-i18next";

import { useLocation } from 'react-router-dom';


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


import mapBg from '../../../../assets/about/map_bg.png';

interface ContactProps {
    count?: number;
    language: string;
}

const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};


const Contact : React.FC<ContactProps> = ({ count = 0, language}) => {
    const { t } = useTranslation();

     const location = useLocation();
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#contact') {
          // 将页面滚动到目标组件的位置
          targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

     const control = useAnimation();
     const [ref, inView] = useInView();
     useEffect(() => {
          if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
     }, [control, inView]);


    return(
     <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
        <div className="aboutContact" ref={targetRef}>
            <div className="aboutContact__map">
                 <div className="aboutContact__title">{t("about.contact_title")}</div>
                <img  src={mapBg} alt="" className="aboutContact__mapImg" />
                  <div className="lightsWrap">
                     <i className={count % 3 === 0 ? "light__iconWhite light": "light__iconWhite"}  style={{top: '40%', left: '15%'}}></i>
                     <i className={count % 2 === 0 ? "light__iconWhite light": "light__iconWhite"} style={{top: '28%', right: '18%'}}></i>
                     <i className={count % 3 === 0 ? "light__iconWhite light": "light__iconWhite"} style={{top: '40%', right: '19%'}}></i>
                     <i className={count % 3 === 0 ? "light__iconWhite light": "light__iconWhite"} style={{top: '40%', right: '18%'}}></i>
                     <i className={count % 3 === 0 ? "light__iconGolden light": "light__iconGolden"} style={{top: '42%', right: '18%'}}></i>

                    <span className="text__pos" style={{top: '44%', left: '18%'}} >North America</span>

                     <span className="text__pos" style={{top: '33%', right: '14%'}} >South Korea</span>
                     <span className="text__pos" style={{top: '48%', right: '16%'}} >Taiwan</span>
                     <span className="text__pos" style={{top: '41%', right: '21%'}} >Shen Zhen</span>
                     <span className="text__pos" style={{top: '47%', right: '20%'}} >Hong Kong</span>
                </div>
            </div>
            <div className={language === 'en' ? "aboutContact__company en": "aboutContact__company" }>
                <div className="aboutContact__companyName">{t("about.company_shenzhen")}</div>
                <div className="aboutContact__address">
                    <i className="aboutContact__address-icon"></i>
                    <div className="aboutContact__addressRight">
                            <div className="aboutContact__address-detail">{t("about.contact_address_1")}</div>
                            <div className="aboutContact__address-detail">{t("about.contact_address_2")}</div>
                    </div>
                </div>
                <div className="aboutContact__email">
                    <i className="aboutContact__email-icon"></i>
                    <div className="aaboutContact__emailDetail">
                     business@terabyte-global.com
                    </div>
                </div>
            </div>
        </div>
    </motion.div>
    )
}

export default Contact;